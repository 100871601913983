html:not(.no-js) {
	.is-loading {
		*,*:before,*:after {
			-webkit-animation: none !important;
			        animation: none !important;
			-webkit-transition: none !important;
			        transition: none !important;
		}
		&:after {
			$size: 62px;
			content: '';
			position: fixed;
			left: 50%;
			top: 50%;
			border: 5px solid rgba($color_main, .1);
			border-top-color: $color_main;
			width: $size;
			height: $size;
			border-radius: 50%;
			margin-top: -($size / 2);
			margin-left: -($size / 2);
			-webkit-animation: spin 1s linear infinite;
			        animation: spin 1s linear infinite;
		}
		.page {
			visibility: hidden;
		}
	}
	.page {
		-webkit-animation: fadein 1s;
		        animation: fadein 1s;
	}
}