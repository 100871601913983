
.d{
	&-b{
		display: block !important;
	}
	&-ib{
		display: inline-block !important;
	}
	&-i{
		display: inline !important;
	}
}
