body {
	@if $type == "all"{
        width: 100%;
        min-width: $inner_width + 40;
		//overflow: hidden;
	}
}

.page {
	@if $type == "all"{
        border-top: 6px solid #0b409c;
		position: relative;
	}
//	@if $type == "sp" or $type == "tab"{
//		left: 0;
//		transition: left .2s;
//		width: auto;
//	}
}

// 判定checkbox
#is-sp-checkbox {
	@if $type == "all"{
		display: none;
	}
	@if $type == "sp" or $type == "tab"{
		&:checked + .page{
			left: -240px;
			// transform: translate(-240px,0);
			.btn-sp-menu{
				&:after{
					visibility: visible;
					opacity: 1;
				}
				i:before{
					content: '\e601';
					position: relative;
					z-index: 101;
				}
			}
		}
	}
}

// メニューボタン（ボタン）
//.btn-sp-menu {
//	@if $type == "all"{
//		display: none;
//	}
//	@if $type == "sp" or $type == "tab"{
//		display: block;
//		margin: 0 5px 0 0;
//		float: right;
//		width: 42px;
//		height: 42px;
//		cursor: pointer;
//		text-align: center;
//		background-color: $color_main;
//		i{
//			margin: 7px 0 0;
//			font-size: 28px;
//			color: #fff;
//			line-height: 42px;
//		}
//		&:after{
//			content: '';
//			position: absolute;
//			background: rgba(#000,.8);
//			top: 0;
//			left: 0;
//			width: 100%;
//			height: 100%;
//			z-index: 100;
//			visibility: hidden;
//			opacity: 0;
//			transition: visibility .4s,opacity .4s;
//		}
//	}
//}

// ----------------------------------------------------
// inner
// ----------------------------------------------------

.l-inner {
	@if $type == "all"{
		//@include clearfix();
		margin: 0 auto;
		width: $inner_width;
        overflow: hidden;
	}
//	@if $type == "tab"{
//		width: auto;
//		margin: 0 $padding_tab;
//	}
//	@if $type == "sp"{
//		width: auto;
//		margin: 0 $padding_sp;
//	}
}

// ----------------------------------------------------
// header
// ----------------------------------------------------

.page-header {
	@if $type == "all"{
        position: relative;
        width: $inner_width;
        height: 94px;
        margin: 0 auto;
        h1 {
            position: absolute;
            margin: 0;
            top: 22px;
            left: 0;
            z-index: 100;
            a { display: block; }
        }
	}
}
.logo-main {
	@if $type == "all"{
	}
}

// ----------------------------------------------------
// global navigation
// ----------------------------------------------------

.nav-main {
	@if $type == "all"{
        position: relative;
        &__list {
            display: flex;
            justify-content: flex-end;
            margin-right: 100px;
            li {
                position: relative;
                color: #999;
                //margin-top: 10px;
                font-family: $sub_font;
                font-size: 1.8rem;
                font-weight: bold;
                &:before {
                    position: absolute;
                    background: #999;
                    width: 1px;
                    height: 22px;
                    top: 50%;
                    left: 0;
                    margin-top: -11px;
                    content: "";
                }
                &:first-child:before { display: none; }
                i {
                    color: #999;
                    font-family: webicon;
                    font-size: 4.5rem;
                    &:before {
                        position: absolute;
                        top: 28px;
                        left: 15px;
                        bottom: 0; 
                    }
                }
                span {
                    display: block;
                    color: #3b60a0;
                    font-size: 1.1rem;
                }
                a {
                    display: block;
                    padding: 10px 20px 20px 65px;
                    color: $color_font;
                    text-decoration: none;
                    text-align: center;
                    &.is-current,
                    &:hover {
                        background: rgba(255,206,99,0.65);
                    }
                }
            }
        }
        &__btn {
            position: absolute;
            background: #0b409c;
            width: 100px;
            right: 0;
            top: 0;
            font-family: $sub_font;
            font-size: 1.5rem;
            font-weight: bold;
            letter-spacing: 0;
            text-align: center;
            a {
                display: block;
                height: 94px;
                padding-top: 15px;
                color: #fff;
                text-decoration: none;
                i { font-size: 5.0rem; }
                &.is-current,
                &:hover {
                    color: $color_sub;
                    i { color: $color_sub; }
                }
            }
        }
	}
}


// ----------------------------------------------------
// contents layout
// ----------------------------------------------------

.page-content {
	@if $type == "all"{
        padding-bottom: 130px;        
        h2 {
            background: rgba(11,64,156,0.8);
            display: inline-block;
            padding: 15px 30px 10px 20px;
            color: #fff;
            font-family: $sub_font;
            font-size: 3.4rem;
            line-height: 1;
            letter-spacing: 0.1em;
            span { padding-left: 15px; font-size: 1.6rem; }
            i {
                position: relative;
                display: inline-block;
                width: 70px;
                height: 65px;
                margin-top: -5px;
                font-size: 6.5rem;
                vertical-align: middle;
                line-height: 1;
                &:before {
                    position: absolute;
                }
            }
        }
        h3 {
            color: $color-main;
            font-family: $sub_font;
            font-size: 2.8rem;
        }
	}
}
.content {
	@if $type == "all"{
        margin-top: 100px;
        p {
            line-height: 2.0;
        }
        .bg & {
            margin-top: 0;
            padding: 50px 0;
        }
	}
}

.bg {
    @if $type == "all"{
        margin-top: 100px;
    }
}

// ----------------------------------------------------
// breadcrumbs
// ----------------------------------------------------

.breadcrumbs {
	@if $type == "all"{
        margin-top: -15px;
        text-align: right;
        li {
            display: inline;
            font-size: 1.2rem;
            &:after {
                content: " > ";
            }
            &:last-child:after { display: none; }
            a {
                text-decoration: none;
            }
        }
	}
}

// ----------------------------------------------------
// corporate-identity
// ----------------------------------------------------

#corporate-identity {
	@if $type == "all"{
        padding-top: 30px;
        .content {
            //@include clearfix();
		    width: $inner_width;
            margin: 50px auto 0;
            border: 1px solid #999;
            ol {
                width: 73%;
                margin: 30px auto  40px;
                //margin: 10px 110px;
                li {
                    margin-top: 10px;
                    padding: 0 0 0 2.4em;
                    text-indent: -2.4em;
                    font-size: 2.2rem;
                    font-weight: bold;
                    &:before {
                        font-size: 3.0rem;
                        vertical-align: middle;
                    }
                }
            }
            h3 {
                display: block;
                background: #fff;
                width: 6em;
                margin: -25px auto 0;
                text-align: center;
            }
        }
	}
}

// ----------------------------------------------------
// inquery-list
// ----------------------------------------------------

.inquery-list {
    @if $type == "all"{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 540px;
        padding: 20px 7px 20px;
        border: 1px solid #10316b;
        p {
            width: 100%;
            color: $color_main;
            font-family: $sub_font;
            font-size: 2.0rem;
            font-weight: bold;
            text-align: center;
            &.btn-mail {
                background: #10316b;
                width: 40%;
                text-align: left;
                a {
                    display: block;
                    padding: 5px 0 5px 20px;
                    color: #fff;
                    font-weight: normal;
                    vertical-align: middle;
                    text-decoration: none;
                    &:hover { color: $color_sub;}
                    &:before {
                        margin-right: 5px;
                        color: $color_sub;
                        font-family: webicon;
                        font-size: 3.6rem;
                        content: "\e900";
                    }
                }
            }
        }
        .tel-list {
            width: 50%;
            font-family: $sub_font;
            color: $color_main;
            dt {
                display: block;
                margin-bottom: -5px;
                font-size: 1.4rem;
                font-weight: bold;
                padding-left: 7px;
            }
            dd {
                font-size: 3.2rem;
                font-weight: bold;
                text-align: center;
                &.reception {
                    background: $color_sub;
                    font-size: 1.6rem;
                    text-align: center;
                    span {
                        font-size: 1.2rem;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}


// ----------------------------------------------------
// col2
// ----------------------------------------------------

.col2 {
    @if $type == "all"{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .content {
            width: 46%;
        }
        &-imgR {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .content {
                &:first-child {
                    width: 730px;
                }
                &:nth-child(2n) {
                    width: 310px;
                }
            }
        }
    }
}


// ----------------------------------------------------
// list
// ----------------------------------------------------

.list {
    @if $type == "all"{
        &-table {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 15px;
            border-bottom: 1px solid #999;
            dt, dd {
                display: flex;
                padding-top: 15px;
                padding-bottom: 15px;
                border-top: 1px solid #999;
                box-sizing: border-box;
            }
            dt {
                width: 17%;
                padding-left: 15px;
                color: $color-main;
                text-align: right;
                &.middle { align-items: center; }
            }
            dd {
                width: 83%;
                padding-left: 40px;
                padding-right: 15px;
                color: #000;
                align-items: center;
                &.top { align-items: flex-start; }
            }
            .col2 & {
                dt, dd { padding-top: 15px; padding-bottom: 15px; }
                dt { width: 30%; text-align: left; }
                dd { width: 70%; padding-left: 15px; }
            }
        }
        &-disc {
            li {
                position: relative;
                padding: 0 0 10px 18px;
                &:before {
                    position: absolute;
                    color: #10316b;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    content: "●";
                }
            }
        }
        &-btn {
            display: flex;
            width: 100%;
            justify-content: space-between;
            li {
                background: #10316b;
                width: 48%;
                font-family: $sub_font;
                font-size: 1.8rem;
                letter-spacing: 0.1em;
                &:first-child {
                    margin-top: none;
                }
                a {
                    display: block;
                    padding: 9px 0 8px 10px;
                    color: #fff;
                    vertical-align: middle;
                    text-decoration: none;
                    &:hover { color: $color_sub;}
                    &:before {
                        margin-right: 5px;
                        color: $color_sub;
                        font-family: webicon;
                        font-size: 3.6rem;
                        content: "\e900";
                    }
                }
            }
            &.btn-submit {
                width: 200px;
                margin:  30px auto 0;
                li {
                    position: relative;
                    width: 100%;
                    display: block;
                    padding: 0;
                    color: #fff;
                    vertical-align: middle;
                    text-decoration: none;
                    &:hover { color: $color_sub;}
                    &:before {
                        position: absolute;
                        top: 50%;
                        margin-top: -28px;
                        left: 10px;
                        color: $color_sub;
                        font-family: webicon;
                        font-size: 3.6rem;
                        content: "\e900";
                    }
                    input {
                        display: inline-block;
                        width: 100%;
                        padding: 9px 0 8px 53px;
                        text-align: left;
                        line-height: 50px;
                        letter-spacing: 0.2em;
                    }
                }
            }
        }
    }
}

// ----------------------------------------------------
// footer
// ----------------------------------------------------

footer {
    @if $type == "all"{
        background: #10316b;
        .page-footer {
            &:first-child {
                border-bottom: 1px solid #4a6ba4;
                .l-inner {
                    padding: 0 0 20px;
                }
            }
            .l-inner {
                position: relative;
                padding: 0;
                overflow: hidden;
            }
            .author {
                margin-top: 20px;
                color: #fff;
                dt {
                    font-family: $sub-font;
                    font-size: 2.2rem;
                    font-weight: bold;
                    letter-spacing: 0.1em;
                    span { font-size: 1.8rem; }
                }
                dd {
                    font-size: 1.4rem;
                    span { font-size: 1.2rem; }
                }
            }
            .nav-doppel {
                position: absolute;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 230px;
                margin: -30px 0 0;
                right: 70px;
                top: 50%;
                li {
                    width: 50%;
                    font-size: 1.4rem;
                    a {
                        display: block;
                        color: #fff;
                        //padding-left: 25px;
                        text-decoration: none;
                        line-height: 1.2;
                        &:hover { color: $color_sub; }
                        &:before {
                            margin-right: 5px;
                            color: $color_sub;
                            font-size: 2.7rem;
                            font-family: webicon;
                            content: "\e900";
                            vertical-align: middle;
                        }
                    }
                }
            }
            .movetop {
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -27px;
                a {
                    position: relative;
                    display: block;
                    background: $color_sub;
                    width: 54px;
                    height: 54px;
                    text-decoration: none;
                    &:hover { background: #fff; }
                    &:before {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        color: $color_main;
                        font-size: 4.0rem;
                        font-family: webicon;
                        content: "\e903";
                        vertical-align: middle;
                        text-align: center;
                    }
                }
            }
            .nav-sub {
                float: right;
                display: flex;
                justify-content: flex-end;
                //margin: 10px 0;
                li {
                    font-size: 1.2rem;
                    a {
                        display: block;
                        color: #fff;
                        padding: 10px 0 10px 25px;
                        text-decoration: none;
                        &:hover { color: $color_sub; }
                        &:before {
                            color: $color_sub;
                            font-size: 1.7rem;
                            font-family: webicon;
                            content: "\e902";
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        .copyright {
            float: left;
            margin: 12px 0 0;
            small {
                color: #b5caee;
                font-size: 1.2rem;
            }
        }
    }
}


