$start: 10;
$end: 32;
$unit: rem;

.f {
//    @for $i from $start through $end {
//        &#{$i}{
//            font-size: ($i / 10)#{$unit} !important;
//        }
//    }
	&z-10 { font-size: 1.0rem !important; }
	&z-11 { font-size: 1.1rem !important; }
    &z-12 { font-size: 1.2rem !important; }
    &z-13 { font-size: 1.3rem !important; }
    &z-14 { font-size: 1.4rem !important; }
    &z-15 { font-size: 1.5rem !important; }
    &z-16 { font-size: 1.6rem !important; }
    &z-17 { font-size: 1.7rem !important; }
    &z-18 { font-size: 1.8rem !important; }
}
