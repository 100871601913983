
// ベースカラー
$color_bg: #fff;

// テキストカラー
$color_font: #333;

// メインカラー
$color_main: #0b409c;

// サブカラー（ベースカラー）
$color_sub: #ffce63;

// アクセントカラー
$color_accent: #cc3e55;

// ラインカラー
$color_line: #ccc;
