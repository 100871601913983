

// ページ全体の横幅
$inner_width: 1080px;

// メディアクエリの幅設定
$mq_pc_width: $inner_width;
$mq_tab_width: 640px;

$padding_tab: 16px;
$padding_sp: 8px;