@charset "UTF-8";
/*! sanitize.css v3.0.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/* Normalization */
audio:not([controls]) { display: none; }

button { overflow: visible; -webkit-appearance: button; }

details { display: block; }

html { -ms-overflow-style: -ms-autohiding-scrollbar; overflow-y: scroll; -webkit-text-size-adjust: 100%; }

input { -webkit-border-radius: 0; }

input[type="button"], input[type="reset"], input[type="submit"] { -webkit-appearance: button; }

input[type="number"] { width: auto; }

input[type="search"] { -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

main { display: block; }

pre { overflow: auto; }

progress { display: inline-block; }

small { font-size: 75%; }

summary { display: block; }

svg:not(:root) { overflow: hidden; }

template { display: none; }

textarea { overflow: auto; }

[hidden] { display: none; }

/* Universal inheritance */
*, ::before, ::after { box-sizing: inherit; }

* { font-size: inherit; line-height: inherit; }

::before, ::after { text-decoration: inherit; vertical-align: inherit; }

/* Opinionated defaults */
*, ::before, ::after { border-style: solid; border-width: 0; }

* { background-repeat: no-repeat; margin: 0; padding: 0; }

:root { background-color: #FFFFFF; box-sizing: border-box; color: #000000; cursor: default; font: 62.5%/1.5 sans-serif; text-rendering: auto; }

a { text-decoration: none; }

audio, canvas, iframe, img, svg, video { vertical-align: middle; }

button, input, select, textarea { background-color: transparent; }

button, input, select, textarea { color: inherit; font-family: inherit; font-style: inherit; font-weight: inherit; }

button, [type="button"], [type="date"], [type="datetime"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="reset"], [type="search"], [type="submit"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], select, textarea { min-height: 1.5em; }

code, kbd, pre, samp { font-family: monospace, monospace; }

nav ol, nav ul { list-style: none; }

select { -moz-appearance: none; -webkit-appearance: none; }

select::-ms-expand { display: none; }

select::-ms-value { color: currentColor; }

table { border-collapse: collapse; border-spacing: 0; }

textarea { resize: vertical; }

::-moz-selection { background-color: #B3D4FC; color: #ffffff; text-shadow: none; }

::selection { background-color: #B3D4FC; color: #ffffff; text-shadow: none; }

[aria-busy="true"] { cursor: progress; }

[aria-controls] { cursor: pointer; }

[aria-disabled] { cursor: default; }

[hidden][aria-hidden="false"] { clip: rect(0 0 0 0); display: inherit; position: absolute; }

[hidden][aria-hidden="false"]:focus { clip: auto; }

html { overflow-y: scroll; font-size: 62.5%; }

body { color: #333; font-family: "メイリオ", "Meiryo", verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif; font-size: 1.5rem; letter-spacing: 0.05em; font-feature-settings: "palt"; }

input, select, option, textarea { font-family: "メイリオ", "Meiryo", verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif; }

a { transition-property: color, border-color, background-color, opacity; transition-duration: .2s; transition-timing-function: ease-in-out; }

a { color: #0b409c; text-decoration: none; }

a:visited { color: #0d4ab4; }

a:hover { outline: 0; color: #093684; text-decoration: underline; }

a:active { outline: 0; color: #cc3e55; }

::selection { background-color: #082c6c; color: #fff; }

h3 { margin-bottom: 15px; }

.for-tab { display: none; }

.for-sp { display: none; }

.row { margin-left: -15px; margin-right: -15px; }

.row:after { content: ""; display: block; clear: both; }

.col-1, .col-tab-1, .col-sp-1, .col-2, .col-tab-2, .col-sp-2, .col-3, .col-tab-3, .col-sp-3, .col-4, .col-tab-4, .col-sp-4, .col-5, .col-tab-5, .col-sp-5, .col-6, .col-tab-6, .col-sp-6, .col-7, .col-tab-7, .col-sp-7, .col-8, .col-tab-8, .col-sp-8, .col-9, .col-tab-9, .col-sp-9, .col-10, .col-tab-10, .col-sp-10, .col-11, .col-tab-11, .col-sp-11, .col-12, .col-tab-12, .col-sp-12 { box-sizing: border-box; float: left; padding: 15px; }

.col-1 :first-child, .col-tab-1 :first-child, .col-sp-1 :first-child, .col-2 :first-child, .col-tab-2 :first-child, .col-sp-2 :first-child, .col-3 :first-child, .col-tab-3 :first-child, .col-sp-3 :first-child, .col-4 :first-child, .col-tab-4 :first-child, .col-sp-4 :first-child, .col-5 :first-child, .col-tab-5 :first-child, .col-sp-5 :first-child, .col-6 :first-child, .col-tab-6 :first-child, .col-sp-6 :first-child, .col-7 :first-child, .col-tab-7 :first-child, .col-sp-7 :first-child, .col-8 :first-child, .col-tab-8 :first-child, .col-sp-8 :first-child, .col-9 :first-child, .col-tab-9 :first-child, .col-sp-9 :first-child, .col-10 :first-child, .col-tab-10 :first-child, .col-sp-10 :first-child, .col-11 :first-child, .col-tab-11 :first-child, .col-sp-11 :first-child, .col-12 :first-child, .col-tab-12 :first-child, .col-sp-12 :first-child { margin-top: 0; }

.col-1 { width: 8.33333%; }

.col-2 { width: 16.66667%; }

.col-3 { width: 25%; }

.col-4 { width: 33.33333%; }

.col-5 { width: 41.66667%; }

.col-6 { width: 50%; }

.col-7 { width: 58.33333%; }

.col-8 { width: 66.66667%; }

.col-9 { width: 75%; }

.col-10 { width: 83.33333%; }

.col-11 { width: 91.66667%; }

.col-12 { width: 100%; }

body { width: 100%; min-width: 1120px; }

.page { border-top: 6px solid #0b409c; position: relative; }

#is-sp-checkbox { display: none; }

.l-inner { margin: 0 auto; width: 1080px; overflow: hidden; }

.page-header { position: relative; width: 1080px; height: 94px; margin: 0 auto; }

.page-header h1 { position: absolute; margin: 0; top: 22px; left: 0; z-index: 100; }

.page-header h1 a { display: block; }

.nav-main { position: relative; }

.nav-main__list { display: flex; justify-content: flex-end; margin-right: 100px; }

.nav-main__list li { position: relative; color: #999; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 1.8rem; font-weight: bold; }

.nav-main__list li:before { position: absolute; background: #999; width: 1px; height: 22px; top: 50%; left: 0; margin-top: -11px; content: ""; }

.nav-main__list li:first-child:before { display: none; }

.nav-main__list li i { color: #999; font-family: webicon; font-size: 4.5rem; }

.nav-main__list li i:before { position: absolute; top: 28px; left: 15px; bottom: 0; }

.nav-main__list li span { display: block; color: #3b60a0; font-size: 1.1rem; }

.nav-main__list li a { display: block; padding: 10px 20px 20px 65px; color: #333; text-decoration: none; text-align: center; }

.nav-main__list li a.is-current, .nav-main__list li a:hover { background: rgba(255, 206, 99, 0.65); }

.nav-main__btn { position: absolute; background: #0b409c; width: 100px; right: 0; top: 0; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 1.5rem; font-weight: bold; letter-spacing: 0; text-align: center; }

.nav-main__btn a { display: block; height: 94px; padding-top: 15px; color: #fff; text-decoration: none; }

.nav-main__btn a i { font-size: 5.0rem; }

.nav-main__btn a.is-current, .nav-main__btn a:hover { color: #ffce63; }

.nav-main__btn a.is-current i, .nav-main__btn a:hover i { color: #ffce63; }

.page-content { padding-bottom: 130px; }

.page-content h2 { background: rgba(11, 64, 156, 0.8); display: inline-block; padding: 15px 30px 10px 20px; color: #fff; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 3.4rem; line-height: 1; letter-spacing: 0.1em; }

.page-content h2 span { padding-left: 15px; font-size: 1.6rem; }

.page-content h2 i { position: relative; display: inline-block; width: 70px; height: 65px; margin-top: -5px; font-size: 6.5rem; vertical-align: middle; line-height: 1; }

.page-content h2 i:before { position: absolute; }

.page-content h3 { color: #0b409c; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.8rem; }

.content { margin-top: 100px; }

.content p { line-height: 2.0; }

.bg .content { margin-top: 0; padding: 50px 0; }

.bg { margin-top: 100px; }

.breadcrumbs { margin-top: -15px; text-align: right; }

.breadcrumbs li { display: inline; font-size: 1.2rem; }

.breadcrumbs li:after { content: " > "; }

.breadcrumbs li:last-child:after { display: none; }

.breadcrumbs li a { text-decoration: none; }

#corporate-identity { padding-top: 30px; }

#corporate-identity .content { width: 1080px; margin: 50px auto 0; border: 1px solid #999; }

#corporate-identity .content ol { width: 73%; margin: 30px auto  40px; }

#corporate-identity .content ol li { margin-top: 10px; padding: 0 0 0 2.4em; text-indent: -2.4em; font-size: 2.2rem; font-weight: bold; }

#corporate-identity .content ol li:before { font-size: 3.0rem; vertical-align: middle; }

#corporate-identity .content h3 { display: block; background: #fff; width: 6em; margin: -25px auto 0; text-align: center; }

.inquery-list { display: flex; justify-content: space-around; flex-wrap: wrap; align-items: flex-end; width: 540px; padding: 20px 7px 20px; border: 1px solid #10316b; }

.inquery-list p { width: 100%; color: #0b409c; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.0rem; font-weight: bold; text-align: center; }

.inquery-list p.btn-mail { background: #10316b; width: 40%; text-align: left; }

.inquery-list p.btn-mail a { display: block; padding: 5px 0 5px 20px; color: #fff; font-weight: normal; vertical-align: middle; text-decoration: none; }

.inquery-list p.btn-mail a:hover { color: #ffce63; }

.inquery-list p.btn-mail a:before { margin-right: 5px; color: #ffce63; font-family: webicon; font-size: 3.6rem; content: "\e900"; }

.inquery-list .tel-list { width: 50%; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; color: #0b409c; }

.inquery-list .tel-list dt { display: block; margin-bottom: -5px; font-size: 1.4rem; font-weight: bold; padding-left: 7px; }

.inquery-list .tel-list dd { font-size: 3.2rem; font-weight: bold; text-align: center; }

.inquery-list .tel-list dd.reception { background: #ffce63; font-size: 1.6rem; text-align: center; }

.inquery-list .tel-list dd.reception span { font-size: 1.2rem; padding-right: 10px; }

.col2 { display: flex; flex-wrap: wrap; justify-content: space-between; }

.col2 .content { width: 46%; }

.col2-imgR { display: flex; flex-wrap: wrap; justify-content: space-between; }

.col2-imgR .content:first-child { width: 730px; }

.col2-imgR .content:nth-child(2n) { width: 310px; }

.list-table { display: flex; flex-wrap: wrap; width: 100%; margin-top: 15px; border-bottom: 1px solid #999; }

.list-table dt, .list-table dd { display: flex; padding-top: 15px; padding-bottom: 15px; border-top: 1px solid #999; box-sizing: border-box; }

.list-table dt { width: 17%; padding-left: 15px; color: #0b409c; text-align: right; }

.list-table dt.middle { align-items: center; }

.list-table dd { width: 83%; padding-left: 40px; padding-right: 15px; color: #000; align-items: center; }

.list-table dd.top { align-items: flex-start; }

.col2 .list-table dt, .col2 .list-table dd { padding-top: 15px; padding-bottom: 15px; }

.col2 .list-table dt { width: 30%; text-align: left; }

.col2 .list-table dd { width: 70%; padding-left: 15px; }

.list-disc li { position: relative; padding: 0 0 10px 18px; }

.list-disc li:before { position: absolute; color: #10316b; top: 0; left: 0; right: 0; bottom: 0; content: "●"; }

.list-btn { display: flex; width: 100%; justify-content: space-between; }

.list-btn li { background: #10316b; width: 48%; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 1.8rem; letter-spacing: 0.1em; }

.list-btn li:first-child { margin-top: none; }

.list-btn li a { display: block; padding: 9px 0 8px 10px; color: #fff; vertical-align: middle; text-decoration: none; }

.list-btn li a:hover { color: #ffce63; }

.list-btn li a:before { margin-right: 5px; color: #ffce63; font-family: webicon; font-size: 3.6rem; content: "\e900"; }

.list-btn.btn-submit { width: 200px; margin: 30px auto 0; }

.list-btn.btn-submit li { position: relative; width: 100%; display: block; padding: 0; color: #fff; vertical-align: middle; text-decoration: none; }

.list-btn.btn-submit li:hover { color: #ffce63; }

.list-btn.btn-submit li:before { position: absolute; top: 50%; margin-top: -28px; left: 10px; color: #ffce63; font-family: webicon; font-size: 3.6rem; content: "\e900"; }

.list-btn.btn-submit li input { display: inline-block; width: 100%; padding: 9px 0 8px 53px; text-align: left; line-height: 50px; letter-spacing: 0.2em; }

footer { background: #10316b; }

footer .page-footer:first-child { border-bottom: 1px solid #4a6ba4; }

footer .page-footer:first-child .l-inner { padding: 0 0 20px; }

footer .page-footer .l-inner { position: relative; padding: 0; overflow: hidden; }

footer .page-footer .author { margin-top: 20px; color: #fff; }

footer .page-footer .author dt { font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.2rem; font-weight: bold; letter-spacing: 0.1em; }

footer .page-footer .author dt span { font-size: 1.8rem; }

footer .page-footer .author dd { font-size: 1.4rem; }

footer .page-footer .author dd span { font-size: 1.2rem; }

footer .page-footer .nav-doppel { position: absolute; display: flex; justify-content: flex-start; flex-wrap: wrap; width: 230px; margin: -30px 0 0; right: 70px; top: 50%; }

footer .page-footer .nav-doppel li { width: 50%; font-size: 1.4rem; }

footer .page-footer .nav-doppel li a { display: block; color: #fff; text-decoration: none; line-height: 1.2; }

footer .page-footer .nav-doppel li a:hover { color: #ffce63; }

footer .page-footer .nav-doppel li a:before { margin-right: 5px; color: #ffce63; font-size: 2.7rem; font-family: webicon; content: "\e900"; vertical-align: middle; }

footer .page-footer .movetop { position: absolute; right: 0; top: 50%; margin-top: -27px; }

footer .page-footer .movetop a { position: relative; display: block; background: #ffce63; width: 54px; height: 54px; text-decoration: none; }

footer .page-footer .movetop a:hover { background: #fff; }

footer .page-footer .movetop a:before { position: absolute; top: 0; right: 0; bottom: 0; left: 0; color: #0b409c; font-size: 4.0rem; font-family: webicon; content: "\e903"; vertical-align: middle; text-align: center; }

footer .page-footer .nav-sub { float: right; display: flex; justify-content: flex-end; }

footer .page-footer .nav-sub li { font-size: 1.2rem; }

footer .page-footer .nav-sub li a { display: block; color: #fff; padding: 10px 0 10px 25px; text-decoration: none; }

footer .page-footer .nav-sub li a:hover { color: #ffce63; }

footer .page-footer .nav-sub li a:before { color: #ffce63; font-size: 1.7rem; font-family: webicon; content: "\e902"; vertical-align: middle; }

footer .copyright { float: left; margin: 12px 0 0; }

footer .copyright small { color: #b5caee; font-size: 1.2rem; }

@media only screen and (max-width: 639px) { .for-pc { display: none; }
  .for-tab { display: none; }
  .for-sp { display: block; }
  .row { margin-left: -8px; margin-right: -8px; }
  .col-1, .col-tab-1, .col-sp-1, .col-2, .col-tab-2, .col-sp-2, .col-3, .col-tab-3, .col-sp-3, .col-4, .col-tab-4, .col-sp-4, .col-5, .col-tab-5, .col-sp-5, .col-6, .col-tab-6, .col-sp-6, .col-7, .col-tab-7, .col-sp-7, .col-8, .col-tab-8, .col-sp-8, .col-9, .col-tab-9, .col-sp-9, .col-10, .col-tab-10, .col-sp-10, .col-11, .col-tab-11, .col-sp-11, .col-12, .col-tab-12, .col-sp-12 { padding: 8px; }
  .col-sp-1 { width: 8.33333%; }
  .col-sp-2 { width: 16.66667%; }
  .col-sp-3 { width: 25%; }
  .col-sp-4 { width: 33.33333%; }
  .col-sp-5 { width: 41.66667%; }
  .col-sp-6 { width: 50%; }
  .col-sp-7 { width: 58.33333%; }
  .col-sp-8 { width: 66.66667%; }
  .col-sp-9 { width: 75%; }
  .col-sp-10 { width: 83.33333%; }
  .col-sp-11 { width: 91.66667%; }
  .col-sp-12 { width: 100%; }
  #is-sp-checkbox:checked + .page { left: -240px; }
  #is-sp-checkbox:checked + .page .btn-sp-menu:after { visibility: visible; opacity: 1; }
  #is-sp-checkbox:checked + .page .btn-sp-menu i:before { content: '\e601'; position: relative; z-index: 101; } }

@media only screen and (min-width: 640px) and (max-width: 1079px) { .for-pc { display: none; }
  .for-tab { display: block; }
  .for-sp { display: none; }
  .row { margin-left: -10px; margin-right: -10px; }
  .col-1, .col-tab-1, .col-sp-1, .col-2, .col-tab-2, .col-sp-2, .col-3, .col-tab-3, .col-sp-3, .col-4, .col-tab-4, .col-sp-4, .col-5, .col-tab-5, .col-sp-5, .col-6, .col-tab-6, .col-sp-6, .col-7, .col-tab-7, .col-sp-7, .col-8, .col-tab-8, .col-sp-8, .col-9, .col-tab-9, .col-sp-9, .col-10, .col-tab-10, .col-sp-10, .col-11, .col-tab-11, .col-sp-11, .col-12, .col-tab-12, .col-sp-12 { padding: 10px; }
  .col-tab-1 { width: 8.33333%; }
  .col-tab-2 { width: 16.66667%; }
  .col-tab-3 { width: 25%; }
  .col-tab-4 { width: 33.33333%; }
  .col-tab-5 { width: 41.66667%; }
  .col-tab-6 { width: 50%; }
  .col-tab-7 { width: 58.33333%; }
  .col-tab-8 { width: 66.66667%; }
  .col-tab-9 { width: 75%; }
  .col-tab-10 { width: 83.33333%; }
  .col-tab-11 { width: 91.66667%; }
  .col-tab-12 { width: 100%; }
  #is-sp-checkbox:checked + .page { left: -240px; }
  #is-sp-checkbox:checked + .page .btn-sp-menu:after { visibility: visible; opacity: 1; }
  #is-sp-checkbox:checked + .page .btn-sp-menu i:before { content: '\e601'; position: relative; z-index: 101; } }

html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

img { max-width: 100%; height: auto; }

.d-b { display: block !important; }

.d-ib { display: inline-block !important; }

.d-i { display: inline !important; }

.f-l { float: left !important; }

.f-r { float: right !important; }

.clear { clear: both; }

.clearfix:after { content: ""; display: block; clear: both; }

.mt-20 { margin-top: -20px !important; }

.mt-15 { margin-top: -15px !important; }

.mt-10 { margin-top: -10px !important; }

.mt-5 { margin-top: -5px !important; }

.mt0 { margin-top: 0px !important; }

.mt5 { margin-top: 5px !important; }

.mt10 { margin-top: 10px !important; }

.mt15 { margin-top: 15px !important; }

.mt20 { margin-top: 20px !important; }

.mt25 { margin-top: 25px !important; }

.mt30 { margin-top: 30px !important; }

.mt35 { margin-top: 35px !important; }

.mt40 { margin-top: 40px !important; }

.mt45 { margin-top: 45px !important; }

.mt50 { margin-top: 50px !important; }

.mt55 { margin-top: 55px !important; }

.mt60 { margin-top: 60px !important; }

.mt65 { margin-top: 65px !important; }

.mt70 { margin-top: 70px !important; }

.mt75 { margin-top: 75px !important; }

.mt80 { margin-top: 80px !important; }

.mt85 { margin-top: 85px !important; }

.mt90 { margin-top: 90px !important; }

.mt95 { margin-top: 95px !important; }

.mt100 { margin-top: 100px !important; }

.mb-20 { margin-bottom: -20px !important; }

.mb-15 { margin-bottom: -15px !important; }

.mb-10 { margin-bottom: -10px !important; }

.mb-5 { margin-bottom: -5px !important; }

.mb0 { margin-bottom: 0px !important; }

.mb5 { margin-bottom: 5px !important; }

.mb10 { margin-bottom: 10px !important; }

.mb15 { margin-bottom: 15px !important; }

.mb20 { margin-bottom: 20px !important; }

.mb25 { margin-bottom: 25px !important; }

.mb30 { margin-bottom: 30px !important; }

.mb35 { margin-bottom: 35px !important; }

.mb40 { margin-bottom: 40px !important; }

.mb45 { margin-bottom: 45px !important; }

.mb50 { margin-bottom: 50px !important; }

.mb55 { margin-bottom: 55px !important; }

.mb60 { margin-bottom: 60px !important; }

.mb65 { margin-bottom: 65px !important; }

.mb70 { margin-bottom: 70px !important; }

.mb75 { margin-bottom: 75px !important; }

.mb80 { margin-bottom: 80px !important; }

.mb85 { margin-bottom: 85px !important; }

.mb90 { margin-bottom: 90px !important; }

.mb95 { margin-bottom: 95px !important; }

.mb100 { margin-bottom: 100px !important; }

.pt0 { padding-top: 0px !important; }

.pt5 { padding-top: 5px !important; }

.pt10 { padding-top: 10px !important; }

.pt15 { padding-top: 15px !important; }

.pt20 { padding-top: 20px !important; }

.pt25 { padding-top: 25px !important; }

.pt30 { padding-top: 30px !important; }

.pt35 { padding-top: 35px !important; }

.pt40 { padding-top: 40px !important; }

.pt45 { padding-top: 45px !important; }

.pt50 { padding-top: 50px !important; }

.pt55 { padding-top: 55px !important; }

.pt60 { padding-top: 60px !important; }

.pt65 { padding-top: 65px !important; }

.pt70 { padding-top: 70px !important; }

.pt75 { padding-top: 75px !important; }

.pt80 { padding-top: 80px !important; }

.pt85 { padding-top: 85px !important; }

.pt90 { padding-top: 90px !important; }

.pt95 { padding-top: 95px !important; }

.pt100 { padding-top: 100px !important; }

.pb0 { padding-bottom: 0px !important; }

.pb5 { padding-bottom: 5px !important; }

.pb10 { padding-bottom: 10px !important; }

.pb15 { padding-bottom: 15px !important; }

.pb20 { padding-bottom: 20px !important; }

.pb25 { padding-bottom: 25px !important; }

.pb30 { padding-bottom: 30px !important; }

.pb35 { padding-bottom: 35px !important; }

.pb40 { padding-bottom: 40px !important; }

.pb45 { padding-bottom: 45px !important; }

.pb50 { padding-bottom: 50px !important; }

.pb55 { padding-bottom: 55px !important; }

.pb60 { padding-bottom: 60px !important; }

.pb65 { padding-bottom: 65px !important; }

.pb70 { padding-bottom: 70px !important; }

.pb75 { padding-bottom: 75px !important; }

.pb80 { padding-bottom: 80px !important; }

.pb85 { padding-bottom: 85px !important; }

.pb90 { padding-bottom: 90px !important; }

.pb95 { padding-bottom: 95px !important; }

.pb100 { padding-bottom: 100px !important; }

.p0 { padding: 0px !important; }

.p5 { padding: 5px !important; }

.p10 { padding: 10px !important; }

.p15 { padding: 15px !important; }

.p20 { padding: 20px !important; }

.p25 { padding: 25px !important; }

.p30 { padding: 30px !important; }

.p35 { padding: 35px !important; }

.p40 { padding: 40px !important; }

.p45 { padding: 45px !important; }

.p50 { padding: 50px !important; }

.p55 { padding: 55px !important; }

.p60 { padding: 60px !important; }

.p65 { padding: 65px !important; }

.p70 { padding: 70px !important; }

.p75 { padding: 75px !important; }

.p80 { padding: 80px !important; }

.p85 { padding: 85px !important; }

.p90 { padding: 90px !important; }

.p95 { padding: 95px !important; }

.p100 { padding: 100px !important; }

.fc-font { color: #333 !important; }

.fc-main { color: #0b409c !important; }

.fc-sub { color: #ffce63 !important; }

.fc-accent { color: #cc3e55 !important; }

.fc-attention { color: #d00 !important; }

.fz-10 { font-size: 1.0rem !important; }

.fz-11 { font-size: 1.1rem !important; }

.fz-12 { font-size: 1.2rem !important; }

.fz-13 { font-size: 1.3rem !important; }

.fz-14 { font-size: 1.4rem !important; }

.fz-15 { font-size: 1.5rem !important; }

.fz-16 { font-size: 1.6rem !important; }

.fz-17 { font-size: 1.7rem !important; }

.fz-18 { font-size: 1.8rem !important; }

.fw-n { font-weight: normal !important; }

.fw-b { font-weight: bold !important; }

.align-l { text-align: left !important; }

.align-c { text-align: center !important; }

.align-r { text-align: right !important; }

ul { list-style-type: none; }

ul.disc { list-style-type: disc; padding: 0 0 0 1.5em; }

ul.circle { list-style: circle; padding: 0 0 0 1.5em; }

ul.square { list-style: square; padding: 0 0 0 1.5em; }

ol { font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; counter-reset: li; /* li のカウンタを 0 にセット */ list-style: none; }

ol li { padding: 0 0 0 1.9em; text-indent: -1.9em; }

ol li:before { color: #0b409c; font-weight: bold; counter-increment: li; /* li の数を一つずつ増加 */ content: counter(li, decimal-leading-zero) ". "; /* 数を表示 */ }

@-webkit-keyframes fadein { 0% { opacity: 0; }
  100% { opacity: 1; } }

@keyframes fadein { 0% { opacity: 0; }
  100% { opacity: 1; } }

@-webkit-keyframes spin { 0% { -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin { 0% { -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.btn-default, .btn-main, .btn-main-o, .btn-sub, .btn-accent { display: inline-block; padding: .5em 1em .35em; text-decoration: none; }

.btn-default:hover, .btn-main:hover, .btn-main-o:hover, .btn-sub:hover, .btn-accent:hover { cursor: pointer; text-decoration: none; }

.btn-lg.btn-default, .btn-lg.btn-main, .btn-lg.btn-main-o, .btn-lg.btn-sub, .btn-lg.btn-accent { padding: .6em 3em .5em; font-size: 1.25em; }

.btn-sm.btn-default, .btn-sm.btn-main, .btn-sm.btn-main-o, .btn-sm.btn-sub, .btn-sm.btn-accent { font-size: .8em; }

.btn-default { border: 1px solid #ccc; background-color: #f6f6f6; color: #333 !important; text-align: center; }

.btn-default:hover { background-color: #fcfcfc; }

.btn-main { border: 1px solid #0b409c; background-color: #0b409c; color: #fff !important; }

.btn-main:hover { background-color: #0e54cc; }

.btn-main-o { border: 1px solid #0b409c; color: #0b409c !important; }

.btn-main-o:hover { background-color: #0b409c; color: #fff !important; }

.btn-sub { border: 1px solid #ffce63; background-color: #ffce63; color: #fff !important; }

.btn-sub:hover { background-color: #ffde96; }

.btn-accent { border: 1px solid #cc3e55; background-color: #cc3e55; color: #fff !important; }

.btn-accent:hover { background-color: #d76679; }

textarea, input, select { padding: 3px; }

[type="submit"], button { cursor: pointer; }

[type="text"], [type="password"], [type="email"], [type="url"], [type="number"], select, textarea { border: 1px solid #ccc; border-radius: 2px; background-color: #fbfbfb; }

[type="text"]:focus, [type="password"]:focus, [type="email"]:focus, [type="url"]:focus, [type="number"]:focus, select:focus, textarea:focus { border-color: #999; background-color: #fefefe; }

::-webkit-input-placeholder { color: #999; }

::-moz-placeholder { color: #999; }

:-ms-input-placeholder { color: #999; }

.placeholder { color: #999; }

html:not(.no-js) .is-loading *, html:not(.no-js) .is-loading *:before, html:not(.no-js) .is-loading *:after { -webkit-animation: none !important; animation: none !important; -webkit-transition: none !important; transition: none !important; }

html:not(.no-js) .is-loading:after { content: ''; position: fixed; left: 50%; top: 50%; border: 5px solid rgba(11, 64, 156, 0.1); border-top-color: #0b409c; width: 62px; height: 62px; border-radius: 50%; margin-top: -31px; margin-left: -31px; -webkit-animation: spin 1s linear infinite; animation: spin 1s linear infinite; }

html:not(.no-js) .is-loading .page { visibility: hidden; }

html:not(.no-js) .page { -webkit-animation: fadein 1s; animation: fadein 1s; }

.grid-row { margin-left: -15px; margin-right: -15px; }

.grid-row:after { content: ""; display: block; clear: both; }

.grid-row + .grid-row { margin-top: 30px; }

.grid-1, .grid-2, .grid-3, .grid-4, .grid-5, .grid-6, .grid-7, .grid-8, .grid-9, .grid-10, .grid-11, .grid-12, .grid-13, .grid-14, .grid-15, .grid-16 { box-sizing: border-box; float: left; padding: 0 15px; }

.grid-1 :first-child, .grid-2 :first-child, .grid-3 :first-child, .grid-4 :first-child, .grid-5 :first-child, .grid-6 :first-child, .grid-7 :first-child, .grid-8 :first-child, .grid-9 :first-child, .grid-10 :first-child, .grid-11 :first-child, .grid-12 :first-child, .grid-13 :first-child, .grid-14 :first-child, .grid-15 :first-child, .grid-16 :first-child { margin-top: 0; }

.grid-1 { width: 6.25%; }

.grid-2 { width: 12.5%; }

.grid-3 { width: 18.75%; }

.grid-4 { width: 25%; }

.grid-5 { width: 31.25%; }

.grid-6 { width: 37.5%; }

.grid-7 { width: 43.75%; }

.grid-8 { width: 50%; }

.grid-9 { width: 56.25%; }

.grid-10 { width: 62.5%; }

.grid-11 { width: 68.75%; }

.grid-12 { width: 75%; }

.grid-13 { width: 81.25%; }

.grid-14 { width: 87.5%; }

.grid-15 { width: 93.75%; }

.grid-16 { width: 100%; }

@font-face { font-family: 'webicon'; src: url("../fonts/webicon.eot?nzi7hi"); src: url("../fonts/webicon.eot?nzi7hi#iefix") format("embedded-opentype"), url("../fonts/webicon.ttf?nzi7hi") format("truetype"), url("../fonts/webicon.woff?nzi7hi") format("woff"), url("../fonts/webicon.svg?nzi7hi#webicon") format("svg"); font-weight: normal; font-style: normal; }

.ico:before, .ico:after, [class^="ico-"], [class*=" ico-"] { font-family: 'webicon'; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.ico-arrow_01:before { content: "\e900"; }

.ico-arrow_02:before { content: "\e901"; }

.ico-arrow_03:before { content: "\e902"; }

.ico-arrow_04:before { content: "\e903"; }

.ico-business:before { content: "\e904"; }

.ico-company:before { content: "\e905"; }

.ico-mail:before { content: "\e906"; }

.ico-performance:before { content: "\e907"; }

.ico-recruit:before { content: "\e908"; }

/** BxSlider v4.1.2 - Fully loaded, responsive content slider http://bxslider.com Written by: Steven Wanderski, 2014 http://stevenwanderski.com (while drinking Belgian ales and listening to jazz) CEO and founder of bxCreative, LTD http://bxcreative.com */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper { position: relative; padding: 0; *zoom: 1; }

.bx-wrapper img { max-width: 100%; height: 470px; object-fit: cover; display: block; }

.bx-wrapper .bx-viewport { background: #fff; /*fix other elements on the page moving (on Chrome)*/ -webkit-transform: translatez(0); -moz-transform: translatez(0); -ms-transform: translatez(0); -o-transform: translatez(0); transform: translatez(0); }

.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto { position: absolute; bottom: 10px; width: 100%; }

.bx-wrapper .bx-loading { min-height: 50px; background: url(../img/common/bx_loader.gif) center center no-repeat white; height: 100%; width: 100%; position: absolute; top: 0; left: 0; z-index: 2000; }

.bx-wrapper .bx-pager { text-align: center; font-size: .85em; font-family: Arial; font-weight: bold; color: #666; padding-top: 20px; }

.bx-wrapper .bx-pager .bx-pager-item { display: inline-block; *zoom: 1; *display: inline; }

.bx-wrapper .bx-controls-auto .bx-controls-auto-item { display: inline-block; *zoom: 1; *display: inline; }

.bx-wrapper .bx-pager.bx-default-pager a { background: #fff; text-indent: -9999px; display: block; width: 10px; height: 10px; margin: 0 5px; outline: 0; -moz-border-radius: 5px; -webkit-border-radius: 5px; border-radius: 5px; }

.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active { background: #ffce63; }

.bx-wrapper .bx-prev { left: 10px; background: url(../img/common/controls.png) no-repeat 0 -32px; }

.bx-wrapper .bx-next { right: 10px; background: url(../img/common/controls.png) no-repeat -43px -32px; }

.bx-wrapper .bx-prev:hover { background-position: 0 0; }

.bx-wrapper .bx-next:hover { background-position: -43px 0; }

.bx-wrapper .bx-controls-direction a { position: absolute; top: 50%; margin-top: -16px; outline: 0; width: 32px; height: 32px; text-indent: -9999px; z-index: 9999; }

.bx-wrapper .bx-controls-direction a.disabled { display: none; }

.bx-wrapper .bx-controls-auto { text-align: center; }

.bx-wrapper .bx-controls-auto .bx-start { display: block; text-indent: -9999px; width: 10px; height: 11px; outline: 0; background: url(../img/common/controls.png) -86px -11px no-repeat; margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-start:hover, .bx-wrapper .bx-controls-auto .bx-start.active { background-position: -86px 0; }

.bx-wrapper .bx-controls-auto .bx-stop { display: block; text-indent: -9999px; width: 9px; height: 11px; outline: 0; background: url(../img/common/controls.png) -86px -44px no-repeat; margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-stop:hover, .bx-wrapper .bx-controls-auto .bx-stop.active { background-position: -86px -33px; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager { text-align: left; width: 80%; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto { right: 0; width: 35px; }

.bx-wrapper .bx-caption { position: absolute; bottom: 0; left: 0; background: #666\9; background: rgba(80, 80, 80, 0.75); width: 100%; }

.bx-wrapper .bx-caption span { color: #fff; font-family: Arial; display: block; font-size: .85em; padding: 10px; }
