
ul {
	list-style-type: none;
	&.disc {
		list-style-type: disc;
		padding: 0 0 0 1.5em;
	}
	&.circle {
		list-style: circle;
		padding: 0 0 0 1.5em;
	}
	&.square {
		list-style: square;
		padding: 0 0 0 1.5em;
	}
}

ol {
    font-family: $sub_font;
    counter-reset: li;    /* li のカウンタを 0 にセット */ 
    list-style: none;
}

ol li {
    padding: 0 0 0 1.9em;
    text-indent: -1.9em;
}

ol li:before {
    color: $color_main;
    
    font-weight: bold;
    counter-increment: li;   /* li の数を一つずつ増加 */
    content: counter(li, decimal-leading-zero) ". ";          /* 数を表示 */
}
