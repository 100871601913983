@font-face {
    font-family: 'webicon';
    src: url('../fonts/webicon.eot?nzi7hi');
    src: url('../fonts/webicon.eot?nzi7hi#iefix') format('embedded-opentype'),
         url('../fonts/webicon.ttf?nzi7hi') format('truetype'),
         url('../fonts/webicon.woff?nzi7hi') format('woff'),
         url('../fonts/webicon.svg?nzi7hi#webicon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ico:before,.ico:after,[class^="ico-"], [class*=" ico-"] {
	font-family: 'webicon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	// /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ico-arrow_01:before {
  content: "\e900";
}
.ico-arrow_02:before {
  content: "\e901";
}
.ico-arrow_03:before {
  content: "\e902";
}
.ico-arrow_04:before {
  content: "\e903";
}
.ico-business:before {
  content: "\e904";
}
.ico-company:before {
  content: "\e905";
}
.ico-mail:before {
  content: "\e906";
}
.ico-performance:before {
  content: "\e907";
}
.ico-recruit:before {
  content: "\e908";
}
