/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */

/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
    position: relative;
    // margin: 0 auto 60px;
    padding: 0;
    *zoom: 1;
    img {
        max-width: 100%;
        height: 470px;
        object-fit: cover;
        display: block;
    }
    .bx-viewport {
    // -moz-box-shadow: 0 0 5px #ccc;
    // -webkit-box-shadow: 0 0 5px #ccc;
    // box-shadow: 0 0 5px #ccc;
    // border: 5px solid #fff;
    // left: -5px;
    background: #fff;
    /*fix other elements on the page moving (on Chrome)*/
    -webkit-transform: translatez(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    transform: translatez(0);
  }
  .bx-pager, .bx-controls-auto {
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  .bx-loading {
    min-height: 50px;
    background: url(../img/common/bx_loader.gif) center center no-repeat white;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
  }
  .bx-pager {
    text-align: center;
    font-size: .85em;
    font-family: Arial;
    font-weight: bold;
    color: #666;
    padding-top: 20px;
    .bx-pager-item {
      display: inline-block;
      *zoom: 1;
      *display: inline;
    }
  }
  .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    *zoom: 1;
    *display: inline;
  }
  .bx-pager.bx-default-pager a {
    background: #fff;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    outline: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    &:hover, &.active {
      background: #ffce63;
    }
  }
  .bx-prev {
    left: 10px;
    background: url(../img/common/controls.png) no-repeat 0 -32px;
  }
  .bx-next {
    right: 10px;
    background: url(../img/common/controls.png) no-repeat -43px -32px;
  }
  .bx-prev:hover {
    background-position: 0 0;
  }
  .bx-next:hover {
    background-position: -43px 0;
  }
  .bx-controls-direction a {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    z-index: 9999;
    &.disabled {
      display: none;
    }
  }
  .bx-controls-auto {
    text-align: center;
    .bx-start {
      display: block;
      text-indent: -9999px;
      width: 10px;
      height: 11px;
      outline: 0;
      background: url(../img/common/controls.png) -86px -11px no-repeat;
      margin: 0 3px;
      &:hover, &.active {
        background-position: -86px 0;
      }
    }
    .bx-stop {
      display: block;
      text-indent: -9999px;
      width: 9px;
      height: 11px;
      outline: 0;
      background: url(../img/common/controls.png) -86px -44px no-repeat;
      margin: 0 3px;
      &:hover, &.active {
        background-position: -86px -33px;
      }
    }
  }
  .bx-controls.bx-has-controls-auto.bx-has-pager {
    .bx-pager {
      text-align: left;
      width: 80%;
    }
    .bx-controls-auto {
      right: 0;
      width: 35px;
    }
  }
  .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #666\9;
    background: rgba(80, 80, 80, 0.75);
    width: 100%;
    span {
      color: #fff;
      font-family: Arial;
      display: block;
      font-size: .85em;
      padding: 10px;
    }
  }
}
